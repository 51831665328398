"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.createMessage = createMessage;
exports.deleteMessage = deleteMessage;
exports.editMessage = editMessage;
exports.getList = getList;
exports.getMessage = getMessage;
exports.pushMessage = pushMessage;
exports.removeMessageAttachment = removeMessageAttachment;

var _request = _interopRequireDefault(require("@/utils/request"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function getList(params) {
  return (0, _request.default)({
    url: '/api/v1/messages',
    method: 'get',
    timeout: 12000,
    // request timeout
    params
  });
}

function getMessage(id) {
  return (0, _request.default)({
    url: '/api/v1/messages/' + id + '/edit',
    method: 'get'
  });
}

function createMessage(data) {
  return (0, _request.default)({
    url: '/api/v1/messages',
    data,
    timeout: 12000,
    // request timeout
    method: 'post'
  });
}

function editMessage(data) {
  return (0, _request.default)({
    url: '/api/v1/messages/' + data.uuid,
    data,
    method: 'put'
  });
}

function deleteMessage(id) {
  return (0, _request.default)({
    url: '/api/v1/messages/' + id,
    method: 'delete'
  });
}

function pushMessage(id) {
  return (0, _request.default)({
    url: '/api/v1/messages/' + id + '/push',
    timeout: 15000,
    // request timeout
    method: 'post'
  });
}

function removeMessageAttachment(id) {
  return (0, _request.default)({
    url: 'api/v1/message-attachment/delete/' + id,
    method: 'post'
  });
}