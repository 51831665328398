"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.bulkUpdateRosterConfirmation = bulkUpdateRosterConfirmation;
exports.getRosterConfirmations = getRosterConfirmations;

var _request = _interopRequireDefault(require("@/utils/request"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function getRosterConfirmations(params) {
  const config = {
    url: `/api/v1/roster-confirmations`,
    method: 'get',
    timeout: 11000 // request timeout

  };

  if (params.signal) {
    config.signal = params.signal;
    delete params.signal;
  }

  config.params = params;
  return (0, _request.default)(config);
}

function bulkUpdateRosterConfirmation(data) {
  return (0, _request.default)({
    url: `/api/v1/roster-confirmations/toggle`,
    timeout: 9500,
    // request timeout
    method: 'post',
    data
  });
}