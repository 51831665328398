"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.activateUser = activateUser;
exports.changePassword = changePassword;
exports.createUser = createUser;
exports.deactivateUser = deactivateUser;
exports.deleteUser = deleteUser;
exports.editUser = editUser;
exports.forgetPassword = forgetPassword;
exports.getInfo = getInfo;
exports.getRoles = getRoles;
exports.getUser = getUser;
exports.getUsers = getUsers;
exports.login = login;
exports.logout = logout;
exports.relogin = relogin;
exports.removeUserAttachment = removeUserAttachment;
exports.resetPassword = resetPassword;

var _request = _interopRequireDefault(require("@/utils/request"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function login(data) {
  return (0, _request.default)({
    url: '/api/v1/oauth/token',
    method: 'post',
    timeout: 10500,
    // request timeout
    data: {
      'grant_type': 'password',
      'client_id': process.env.VUE_APP_OAUTH_CLIENT_ID,
      'client_secret': process.env.VUE_APP_OAUTH_CLIENT_SECRET,
      'username': data.username,
      'password': data.password
    }
  });
}

function relogin(refresh_token) {
  return (0, _request.default)({
    url: '/api/v1/oauth/token',
    method: 'post',
    timeout: 7900,
    // request timeout
    data: {
      'grant_type': 'refresh_token',
      'client_id': process.env.VUE_APP_OAUTH_CLIENT_ID,
      'client_secret': process.env.VUE_APP_OAUTH_CLIENT_SECRET,
      'refresh_token': refresh_token
    }
  });
}

function getInfo() {
  return (0, _request.default)({
    url: '/api/v1/users/me',
    timeout: 2200,
    // request timeout
    method: 'get' // params: { token }

  });
}

function logout() {
  return (0, _request.default)({
    url: '/api/v1/logout',
    method: 'post'
  });
}

function forgetPassword(data) {
  return (0, _request.default)({
    url: `/api/v1/user/forgetPassword`,
    method: 'put',
    data
  });
}

function resetPassword(data) {
  return (0, _request.default)({
    url: `/api/v1/user/resetPassword`,
    method: 'put',
    data
  });
}

function getRoles(params = null) {
  const config = {
    url: '/api/v1/roles',
    method: 'get'
  };

  if (params && params.silentAlert) {
    config.silentAlert = params.silentAlert;
    delete params.silentAlert;
  }

  config.params = params;
  return (0, _request.default)(config);
}

function getUsers(params) {
  const config = {
    url: '/api/v1/users',
    timeout: 6100,
    method: 'get'
  };

  if (params.signal) {
    config.signal = params.signal;
    delete params.signal;
  }

  if (params.silentAlert) {
    config.silentAlert = params.silentAlert;
    delete params.silentAlert;
  }

  config.params = params;
  return (0, _request.default)(config);
}

function getUser(uuid) {
  return (0, _request.default)({
    url: `/api/v1/users/${uuid}`,
    method: 'get'
  });
}

function createUser(data) {
  return (0, _request.default)({
    url: `/api/v1/users`,
    method: 'post',
    data
  });
}

function editUser(data) {
  return (0, _request.default)({
    url: `/api/v1/users/${data.uuid}`,
    method: 'put',
    data
  });
}

function changePassword(data) {
  return (0, _request.default)({
    url: `/api/v1/user/password`,
    method: 'put',
    data
  });
}

function deleteUser(uuid) {
  return (0, _request.default)({
    url: `/api/v1/users/${uuid}`,
    method: 'delete'
  });
}

function activateUser(uuid) {
  return (0, _request.default)({
    url: `/api/v1/users/activate`,
    method: 'post',
    data: {
      id: uuid
    }
  });
}

function deactivateUser(uuid) {
  return (0, _request.default)({
    url: `/api/v1/users/deactivate`,
    method: 'post',
    data: {
      id: uuid
    }
  });
}

function removeUserAttachment(id) {
  return (0, _request.default)({
    url: 'api/v1/user-attachment/delete/' + id,
    method: 'post'
  });
}