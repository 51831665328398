"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _vue = _interopRequireDefault(require("vue"));

var _vueI18n = _interopRequireDefault(require("vue-i18n"));

var _en = _interopRequireDefault(require("@/lang/en"));

var _zh = _interopRequireDefault(require("@/lang/zh"));

var _jsCookie = _interopRequireDefault(require("js-cookie"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

_vue.default.use(_vueI18n.default);

if (!_jsCookie.default.get('crm.lang') || _jsCookie.default.get('crm.lang') === '') {
  _jsCookie.default.set('crm.lang', 'zh', {
    secure: true
  });
} // console.log(Cookies.get());


const i18n = new _vueI18n.default({
  locale: _jsCookie.default.get('crm.lang'),
  // locale: 'zh',
  messages: {
    en: _en.default,
    zh: _zh.default
  }
});
var _default = i18n;
exports.default = _default;