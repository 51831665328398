"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.completeGoodReceivedNote = completeGoodReceivedNote;
exports.confirmGoodReceivedNote = confirmGoodReceivedNote;
exports.createGoodReceivedNote = createGoodReceivedNote;
exports.deleteGoodReceivedNote = deleteGoodReceivedNote;
exports.downloadGoodReceivedNote = downloadGoodReceivedNote;
exports.downloadGoodReceivedNoteTransitNote = downloadGoodReceivedNoteTransitNote;
exports.editGoodReceivedNote = editGoodReceivedNote;
exports.getGoodReceivedNote = getGoodReceivedNote;
exports.getList = getList;
exports.getSummary = getSummary;

var _request = _interopRequireDefault(require("@/utils/request"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function getList(params) {
  return (0, _request.default)({
    url: '/api/v1/good-received-notes',
    method: 'get',
    timeout: 12000,
    // request timeout
    params
  });
}

function getGoodReceivedNote(id) {
  return (0, _request.default)({
    url: '/api/v1/good-received-notes/' + id,
    method: 'get'
  });
}

function getSummary(params) {
  return (0, _request.default)({
    url: '/api/v1/reports/good-received-note',
    method: 'get',
    params
  });
}

function createGoodReceivedNote(data) {
  return (0, _request.default)({
    url: '/api/v1/good-received-notes',
    data,
    method: 'post'
  });
}

function editGoodReceivedNote(data) {
  return (0, _request.default)({
    url: '/api/v1/good-received-notes/' + data.uuid,
    data,
    method: 'put'
  });
}

function deleteGoodReceivedNote(id) {
  return (0, _request.default)({
    url: '/api/v1/good-received-notes/' + id,
    method: 'delete'
  });
}

function confirmGoodReceivedNote(id) {
  return (0, _request.default)({
    url: '/api/v1/good-received-notes/' + id + '/confirm',
    method: 'post'
  });
}

function completeGoodReceivedNote(id) {
  return (0, _request.default)({
    url: '/api/v1/good-received-notes/' + id + '/complete',
    method: 'post'
  });
}

function downloadGoodReceivedNote(id) {
  return (0, _request.default)({
    url: `/api/v1/export-data/good-received-notes/${id}`,
    method: 'GET',
    responseType: 'blob'
  });
}

function downloadGoodReceivedNoteTransitNote(id) {
  return (0, _request.default)({
    url: `/api/v1/export-data/good-received-notes/${id}/transit-note`,
    method: 'GET',
    responseType: 'blob'
  });
}