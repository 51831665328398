"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.completePurchaseRequest = completePurchaseRequest;
exports.confirmPurchaseRequest = confirmPurchaseRequest;
exports.createPurchaseRequest = createPurchaseRequest;
exports.deletePurchaseRequest = deletePurchaseRequest;
exports.downloadPurchaseRequest = downloadPurchaseRequest;
exports.editPurchaseRequest = editPurchaseRequest;
exports.generatePurchaseOrderByStoreFromPurchaseRequest = generatePurchaseOrderByStoreFromPurchaseRequest;
exports.generatePurchaseOrderFromPurchaseRequest = generatePurchaseOrderFromPurchaseRequest;
exports.getList = getList;
exports.getPurchaseRequest = getPurchaseRequest;
exports.previewPurchaseRequest = previewPurchaseRequest;
exports.rejectPurchaseRequest = rejectPurchaseRequest;
exports.submitPurchaseRequest = submitPurchaseRequest;
exports.unsubmitPurchaseRequest = unsubmitPurchaseRequest;

var _request = _interopRequireDefault(require("@/utils/request"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function getList(params) {
  return (0, _request.default)({
    url: '/api/v1/purchase-requests',
    method: 'get',
    timeout: 10000,
    // request timeout
    params
  });
}

function getPurchaseRequest(id) {
  return (0, _request.default)({
    url: '/api/v1/purchase-requests/' + id,
    method: 'get'
  });
}

function createPurchaseRequest(data) {
  return (0, _request.default)({
    url: '/api/v1/purchase-requests',
    data,
    method: 'post'
  });
}

function editPurchaseRequest(data) {
  return (0, _request.default)({
    url: '/api/v1/purchase-requests/' + data.uuid,
    data,
    method: 'put'
  });
}

function deletePurchaseRequest(id) {
  return (0, _request.default)({
    url: '/api/v1/purchase-requests/' + id,
    method: 'delete'
  });
}

function submitPurchaseRequest(id) {
  return (0, _request.default)({
    url: '/api/v1/purchase-requests/' + id + '/submit',
    method: 'post'
  });
}

function unsubmitPurchaseRequest(id) {
  return (0, _request.default)({
    url: '/api/v1/purchase-requests/' + id + '/unsubmit',
    method: 'post'
  });
}

function confirmPurchaseRequest(id) {
  return (0, _request.default)({
    url: '/api/v1/purchase-requests/' + id + '/confirm',
    method: 'post'
  });
}

function rejectPurchaseRequest(id) {
  return (0, _request.default)({
    url: '/api/v1/purchase-requests/' + id + '/reject',
    method: 'post'
  });
}

function completePurchaseRequest(id) {
  return (0, _request.default)({
    url: '/api/v1/purchase-requests/' + id + '/complete',
    method: 'post'
  });
}

function downloadPurchaseRequest(id) {
  return (0, _request.default)({
    url: `/api/v1/export-data/purchase-requests/${id}`,
    method: 'GET',
    responseType: 'blob'
  });
}

function generatePurchaseOrderFromPurchaseRequest(id, suppliers) {
  suppliers = suppliers || [];
  return (0, _request.default)({
    url: '/api/v1/purchase-requests/generate-po',
    method: 'post',
    data: {
      id,
      suppliers
    }
  });
}

function generatePurchaseOrderByStoreFromPurchaseRequest(id, suppliers) {
  suppliers = suppliers || [];
  return (0, _request.default)({
    url: '/api/v1/purchase-requests/generate-po-by-store',
    method: 'post',
    data: {
      id,
      suppliers
    }
  });
}

function previewPurchaseRequest(param) {
  return (0, _request.default)({
    url: '/api/v1/preview/purchase-request',
    method: 'post',
    data: {
      purchaseRequest: param
    }
  });
}