"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.deleteWarehouse = deleteWarehouse;
exports.downloadQRs = downloadQRs;
exports.getWarehouses = getWarehouses;

var _request = _interopRequireDefault(require("@/utils/request"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function getWarehouses(params) {
  return (0, _request.default)({
    url: `/api/v1/warehouses`,
    method: 'get',
    timeout: 8500,
    // request timeout
    params
  });
}

function downloadQRs(id) {
  return (0, _request.default)({
    url: `/api/v1/warehouses/${id}/qr-codes/download`,
    method: 'GET',
    responseType: 'blob'
  });
}

function deleteWarehouse(id) {
  return (0, _request.default)({
    url: `/api/v1/warehouses/${id}`,
    method: 'delete'
  });
}