"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.createShift = createShift;
exports.deleteShift = deleteShift;
exports.editShift = editShift;
exports.getShift = getShift;
exports.getShifts = getShifts;

var _request = _interopRequireDefault(require("@/utils/request"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function getShifts(params) {
  return (0, _request.default)({
    url: `/api/v1/shifts`,
    method: 'get',
    timeout: 12000,
    // request timeout
    params
  });
}

function getShift(id) {
  return (0, _request.default)({
    url: `/api/v1/shifts/${id}`,
    method: 'get'
  });
}

function createShift(data) {
  return (0, _request.default)({
    url: `/api/v1/shifts`,
    data,
    method: 'post'
  });
}

function editShift(data) {
  return (0, _request.default)({
    url: `/api/v1/shifts/${data.id}`,
    data,
    method: 'put'
  });
}

function deleteShift(id) {
  return (0, _request.default)({
    url: `/api/v1/shifts/${id}`,
    method: 'delete'
  });
}