"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _elementUi = require("element-ui");

var _default = {
  mixins: [_elementUi.InputNumber],

  updated() {
    const type = this.$refs.input.$el.children[0].getAttribute('type');

    if (type !== 'number') {
      this.$refs.input.$el.children[0].setAttribute('type', 'number');
    }
  }

};
exports.default = _default;