"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.createWarehouseRecord = createWarehouseRecord;
exports.deleteWarehouseRecord = deleteWarehouseRecord;
exports.editWarehouseRecord = editWarehouseRecord;
exports.getWarehouseRecord = getWarehouseRecord;
exports.getWarehouseRecords = getWarehouseRecords;

var _request = _interopRequireDefault(require("@/utils/request"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function getWarehouseRecords(params) {
  return (0, _request.default)({
    url: '/api/v1/warehouse-records',
    method: 'get',
    timeout: 12000,
    // request timeout
    params
  });
}
/* export function getInventoryLv(params) {
  return request({
    url: '/api/v1/warehouse-inventory-lv',
    method: 'get',
    params
  })
} */


function getWarehouseRecord(id) {
  return (0, _request.default)({
    url: '/api/v1/warehouse-records/' + id,
    method: 'get'
  });
}

function createWarehouseRecord(data) {
  return (0, _request.default)({
    url: '/api/v1/warehouse-records',
    data,
    method: 'post'
  });
}

function editWarehouseRecord(data) {
  return (0, _request.default)({
    url: '/api/v1/warehouse-records/' + data.uuid,
    data,
    method: 'put'
  });
}

function deleteWarehouseRecord(id) {
  return (0, _request.default)({
    url: '/api/v1/warehouse-records/' + id,
    method: 'delete'
  });
}