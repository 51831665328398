"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.createSupplier = createSupplier;
exports.deleteSupplier = deleteSupplier;
exports.editSupplier = editSupplier;
exports.getSupplier = getSupplier;
exports.getSuppliers = getSuppliers;

var _request = _interopRequireDefault(require("@/utils/request"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function getSuppliers(params) {
  let timeout = 10000;

  if (params.purchaseOrderStatus || params.search) {
    timeout = 6000;
  }

  return (0, _request.default)({
    url: '/api/v1/suppliers',
    method: 'get',
    timeout,
    // request timeout
    params
  });
}

function getSupplier(id) {
  return (0, _request.default)({
    url: '/api/v1/suppliers/' + id,
    method: 'get'
  });
}

function createSupplier(data) {
  return (0, _request.default)({
    url: '/api/v1/suppliers',
    data,
    method: 'post'
  });
}

function editSupplier(data) {
  return (0, _request.default)({
    url: '/api/v1/suppliers/' + data.uuid,
    data,
    method: 'put'
  });
}

function deleteSupplier(id) {
  return (0, _request.default)({
    url: '/api/v1/suppliers/' + id,
    method: 'delete'
  });
}