"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.createWarehouseArea = createWarehouseArea;
exports.deleteWarehouseArea = deleteWarehouseArea;
exports.editWarehouseArea = editWarehouseArea;
exports.getWarehouseArea = getWarehouseArea;
exports.getWarehouseAreas = getWarehouseAreas;

var _request = _interopRequireDefault(require("@/utils/request"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function getWarehouseAreas(params) {
  return (0, _request.default)({
    url: '/api/v1/warehouse-areas',
    method: 'get',
    params
  });
}

function getWarehouseArea(id) {
  return (0, _request.default)({
    timeout: 6500,
    url: '/api/v1/warehouse-areas/' + id,
    method: 'get'
  });
}

function createWarehouseArea(data) {
  return (0, _request.default)({
    url: '/api/v1/warehouse-areas',
    data,
    method: 'post'
  });
}

function editWarehouseArea(data) {
  return (0, _request.default)({
    url: '/api/v1/warehouse-areas/' + data.uuid,
    data,
    method: 'put'
  });
}

function deleteWarehouseArea(id) {
  return (0, _request.default)({
    url: '/api/v1/warehouse-areas/' + id,
    method: 'delete'
  });
}