var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "app" } },
    [
      _c("router-view"),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: { visible: _vm.showDialog, width: "90%", center: "" },
          on: {
            "update:visible": function ($event) {
              _vm.showDialog = $event
            },
          },
        },
        [
          _c("span", { attrs: { slot: "title" }, slot: "title" }, [
            _c("div", { staticStyle: { "text-align": "center" } }, [
              _c("img", {
                staticClass: "pic-app",
                attrs: {
                  src: "/img/icons/apple-touch-icon-76x76.png",
                  alt: "app",
                },
              }),
            ]),
            _vm._v(" "),
            _c(
              "h2",
              {
                staticStyle: {
                  "text-align": "center",
                  margin: "0",
                  "font-weight": "initial",
                },
              },
              [_vm._v("Install " + _vm._s(_vm.title))]
            ),
          ]),
          _vm._v(" "),
          _c(
            "h3",
            {
              staticStyle: {
                "text-align": "center",
                color: "grey",
                "font-size": "initial",
                "margin-top": "0",
              },
            },
            [
              _vm._v(
                "Install/Add this application on your homescreen for a better experience."
              ),
            ]
          ),
          _vm._v(" "),
          _c("br"),
          _vm._v(" "),
          _c(
            "div",
            { staticStyle: { "text-align": "center" } },
            [
              _vm._v("Tap "),
              _c("svg-icon", { attrs: { "icon-class": "share-apple" } }),
              _vm._v(' then "Add to Home Screen"'),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      _vm.centerDialogVisible = false
                    },
                  },
                },
                [_vm._v("Got it")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }