"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _scrollTo = require("@/utils/scroll-to");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'Pagination',
  props: {
    total: {
      required: true,
      type: Number
    },
    page: {
      type: Number,
      default: 1
    },
    limit: {
      type: Number,
      default: 20
    },
    pageSizes: {
      type: Array,

      default() {
        return [10, 20, 30, 50];
      }

    },
    background: {
      type: Boolean,
      default: true
    },
    autoScroll: {
      type: Boolean,
      default: true
    },
    hidden: {
      type: Boolean,
      default: false
    },
    pagerCount: {
      type: Number,
      default: null
    },
    layout: {
      type: String,
      default: null // 'total, sizes, prev, pager, next, jumper'

    },
    small: {
      type: Boolean,
      default: null
    }
  },
  computed: {
    device() {
      return this.$store.state.app.device;
    },

    currentPage: {
      get() {
        return this.page;
      },

      set(val) {
        this.$emit('update:page', val);
      }

    },
    pageSize: {
      get() {
        return this.limit;
      },

      set(val) {
        this.$emit('update:limit', val);
      }

    }
  },
  methods: {
    handleSizeChange(val) {
      this.$emit('pagination', {
        page: this.currentPage,
        limit: val
      });

      if (this.autoScroll) {
        (0, _scrollTo.scrollTo)(0, 800);
      }
    },

    handleCurrentChange(val) {
      this.$emit('pagination', {
        page: val,
        limit: this.pageSize
      });

      if (this.autoScroll) {
        (0, _scrollTo.scrollTo)(0, 800);
      }
    }

  }
};
exports.default = _default;