"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.bulkDeleteDuty = bulkDeleteDuty;
exports.bulkDuplicateDuty = bulkDuplicateDuty;
exports.createDuty = createDuty;
exports.deleteDuty = deleteDuty;
exports.editDuty = editDuty;
exports.exportDutiesExcel = exportDutiesExcel;
exports.exportDutiesPDF = exportDutiesPDF;
exports.exportDutiesSalaryBreakdown = exportDutiesSalaryBreakdown;
exports.exportDutiesSalarySummary = exportDutiesSalarySummary;
exports.getDuties = getDuties;
exports.getDuty = getDuty;
exports.getPersonalOT = getPersonalOT;
exports.personalSummary = personalSummary;
exports.possibleDuties = possibleDuties;

var _request = _interopRequireDefault(require("@/utils/request"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function getDuties(params) {
  const config = {
    url: `/api/v1/duties`,
    method: 'get',
    timeout: 9500 // request timeout

  };

  if (params.signal) {
    config.signal = params.signal;
    delete params.signal;
  }

  config.params = params;
  return (0, _request.default)(config);
}

function getDuty(id) {
  return (0, _request.default)({
    url: `/api/v1/duties/${id}`,
    method: 'get'
  });
}

function createDuty(data) {
  return (0, _request.default)({
    url: `/api/v1/duties`,
    data,
    method: 'post'
  });
}

function editDuty(data) {
  return (0, _request.default)({
    url: `/api/v1/duties/${data.id}`,
    data,
    method: 'put'
  });
}

function deleteDuty(id) {
  return (0, _request.default)({
    url: `/api/v1/duties/${id}`,
    method: 'delete'
  });
}

function exportDutiesExcel(params) {
  return (0, _request.default)({
    url: `/api/v1/duties`,
    method: 'get',
    headers: {
      'Accept': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    },
    responseType: 'blob',
    params
  });
}

function exportDutiesPDF(params) {
  return (0, _request.default)({
    url: `/api/v1/duties`,
    method: 'get',
    headers: {
      'Accept': 'application/pdf'
    },
    responseType: 'blob',
    params
  });
}

function exportDutiesSalaryBreakdown(params) {
  return (0, _request.default)({
    url: `/api/v1/duties/salary-breakdown`,
    method: 'get',
    responseType: 'blob',
    params
  });
}

function exportDutiesSalarySummary(params) {
  return (0, _request.default)({
    url: `/api/v1/duties/salary-summary`,
    method: 'get',
    responseType: 'blob',
    params
  });
}

function bulkDuplicateDuty(data) {
  return (0, _request.default)({
    url: `/api/v1/duties/duplicate`,
    method: 'post',
    data
  });
}

function bulkDeleteDuty(data) {
  return (0, _request.default)({
    url: `/api/v1/duties/clearAll`,
    method: 'post',
    data
  });
}

function possibleDuties(params) {
  return (0, _request.default)({
    url: `/api/v1/duties/current`,
    method: 'get',
    params
  });
}

function personalSummary(params) {
  return (0, _request.default)({
    url: `/api/v1/duties/personal-summary`,
    method: 'get',
    responseType: 'blob',
    params
  });
}

function getPersonalOT(params) {
  return (0, _request.default)({
    url: '/api/v1/duties/ot',
    method: 'get',
    params
  });
}