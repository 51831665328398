var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-breadcrumb",
    { staticClass: "app-breadcrumb", attrs: { separator: "/" } },
    [
      _c(
        "transition-group",
        { attrs: { name: "breadcrumb" } },
        _vm._l(_vm.levelList, function (item, index) {
          return _c(
            "el-breadcrumb-item",
            { key: item.path + item.name },
            [
              item.redirect === "noRedirect" ||
              index === _vm.levelList.length - 1
                ? _c("span", { staticClass: "no-redirect" }, [
                    _vm._v(_vm._s(_vm.$t("route." + item.meta.title))),
                  ])
                : _c(
                    "router-link",
                    {
                      attrs: {
                        to: item.redirect
                          ? item.redirect
                          : _vm.pathCompile(item.path),
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("route." + item.meta.title)))]
                  ),
            ],
            1
          )
        }),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }